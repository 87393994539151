var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Choose date range"},model:{value:(_vm.state.dateRange),callback:function ($$v) {_vm.$set(_vm.state, "dateRange", $$v)},expression:"state.dateRange"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.state.isDatePickerActive),callback:function ($$v) {_vm.$set(_vm.state, "isDatePickerActive", $$v)},expression:"state.isDatePickerActive"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":""},on:{"change":_vm.fetchTableData},model:{value:(_vm.state.dateRange),callback:function ($$v) {_vm.$set(_vm.state, "dateRange", $$v)},expression:"state.dateRange"}})],1),_c('v-data-table',{attrs:{"loading":_vm.state.isLoading,"items":_vm.state.tableData,"headers":_vm.state.headers},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.from)))])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.to)))])]}},{key:"item.screenshots",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"align-self-center",attrs:{"small":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.setClickedActivityId(item.activityId)}}},[_vm._v(" View ")])]}}])}),(_vm.isDetailsOpen)?_c('screenshots-popup'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }